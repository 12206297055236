<template>
    <div class="row">
        <div class="col-12" v-if="!mask_ifce">
            <div class="col-12">
                <h4>{{ $t('monte.create_jument_auto') }}</h4>
            </div>
            <div class="col-12">
                <div class="form-group search mb-0 row">
                    <div class="col-12 col-md">
                        <input type="search" name="search" class="form-control " id="search" autofocus="true" v-model="global_input_search" :placeholder="$t('global.input_recherche_cheval')">
                    </div>
                    <div class="col-12 col-md-auto mt-3 mt-md-0">
                        <button type="submit" class="w-100 btn btn-primary">{{ $t('global.rechercher') }} <font-awesome-icon :icon="['fal', 'search']" class="ml-2" /></button>
                    </div>
                </div>

                <template v-if="isWorking || input_type_working == 1" >
                    <div>
                        <LoadingSpinner/>
                    </div>
                </template>
                <template v-else>
                    <template v-if="result_horses.length > 0 && formatted_horses.length > 0">
                        <div class="mt-2">
                            <CustomTable
                                v-show="!isWorking"
                                id_table="horse_ajout"
                                :items="formatted_horses"
                                :busy.sync="table_busy"
                                primaryKey="horse_sire"
                                @row-select="addHorseMethod"
                                :checkboxes="false"
                                :filtre_general="false"
                                selectMode="single"
                                :rowSelectable="!isWorking"
                                :display_action_button="false"
                                :externSlotColumns="extern_slot_columns"
                            >
                                <template v-slot:custom-slot-cell(button_add)="{ data }">
                                    <button class="btn btn-primary" type="button" @click.prevent="addHorseMethod(data)">{{ $t('global.add_this_horse') }}</button>
                                </template>
                            </CustomTable>
                        </div>
                    </template>
                    <template v-else-if="formatted_horses.length == 0 && global_input_search != ''">
                        <div class="mt-2">
                            <b-alert variant="danger" show>{{ error_message_trad }}</b-alert>
                        </div>
                    </template>
                </template>
            </div>
            <div class="col-12 my-2">
                <hr>
            </div>
        </div>
        <div class="col-12">
            <h4 v-if="!mask_ifce">{{ $t('monte.or_create_jument_manuel') }}</h4>
            <h4 v-else>{{ $t('monte.create_jument_manuel') }}</h4>
            <form @submit.prevent="checkForm">

                <!-- Nom -->
                <div class="form-group">
                    <label>{{ $t('horse.form.nom') }}<sup>*</sup></label>

                    <div class="input-group">
                        <input class="form-control" type="text" :placeholder="$t('horse.form.nom')" v-model="horse_nom" id="horse_nom" required>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <!-- Sexe -->
                        <div class="form-group">
                            <label>{{ $t('horse.form.sexe') }}<sup>*</sup></label>
							<e-select
								v-model="horse_sexe"
								id="sexe_code"
								track-by="sexe_code"
								label="sexe_label"
								:placeholder="$t('horse.placeholder.sexe')"
								:selectedLabel="$t('select.selectedLabel')"
								:options="horse_sexes"
								:searchable="true"
								:allow-empty="false"
								:show-labels="false"
							>
								<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- Robe -->
                        <div class="form-group">
                            <label>{{ $t('horse.form.robe') }}</label>
							<e-select
								v-model="horse_robe"
								id="robe_code"
								track-by="robe_code"
								label="robe_label"
								:placeholder="$t('horse.placeholder.robe')"
								:selectedLabel="$t('select.selectedLabel')"
								:options="horse_robes"
								:searchable="true"
								:allow-empty="false"
								:show-labels="false"
							>
								<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- Race -->
                        <div class="form-group">
                            <label>{{ $t('horse.form.race') }}</label>
							<e-select
								v-model="horse_race"
								id="race_code"
								track-by="race_code"
								label="race_label"
								:placeholder="$t('horse.placeholder.race')"
								:selectedLabel="$t('select.selectedLabel')"
								:options="horse_races"
								:searchable="true"
								:allow-empty="false"
								:show-labels="false"
							>
								<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
                        </div>
                    </div>
                </div>

                <!-- Date de naissance -->
                <div class="form-group">
                    <label>{{ $t('horse.form.datenaissance') }}</label>
                    <e-datepicker id="date" v-model="horse_datenaissance"></e-datepicker>
                </div>

                <!-- Message d'erreur si besoin -->
                <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                <!-- Bouton de validation du formulaire -->
	               
	            <div class="text-center mt-5">
	                <button type="submit" class="btn btn-primary rounded-pill">
	                    {{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus-circle']" class="ml-1"/>
	                </button>
	                <b-button @click="cancel" class="btn btn-primary rounded-pill ml-1">
						{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/> 
					</b-button>
	            </div>
            </form>
        </div>
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus';

    import ConfigMixin from '@/mixins/Config';
    import Gynecologie from '@/mixins/Gynecologie';
    import HorseMixin from '@/mixins/Horse';
    import Tools from '@/mixins/Tools';
    import Shutter from "@/mixins/Shutter.js"
    import MonteShutters from '@/mixins/shutters-manager/Monte'

    import Request from '@/assets/js/requests.js'
    import Constants from '../../../config.local.js'
    import _debounce from 'lodash/debounce';
    import Common from '@/assets/js/common.js'

    export default {
        name: "HorseCreationMonte",
        props: ['season_id', 'unknown', 'code_onboarding', 'contract_num'],
        mixins: [ConfigMixin, Gynecologie, HorseMixin, Tools, Shutter, MonteShutters],
        data () {
            return {
                horse_races: [],
                horse_robes: [],
                horse_sexes: [],

                horse_nom: null,
                horse_race: null,
                horse_robe: null,
                horse_sexe: null,
                horse_datenaissance: null,

                required_values: [
                        'horse_nom',
                        'horse_sexe',
                    ],
                message_erreur_code: '',

                request: new Request(this.$db),
                connectivity: this.$connectivity,

                first_login: false,
                has_right: false,
                global_input_search: "",
                result_horses: [],
                selected_horse: null,
                code_erreur: "",
                search_type: "", // SIRE / UELN / NAME
                search_done: false, // passe à true dès qu'on a un résultat sur la recherche, et repasse à false dès qu'on passe en dessous des 5 cara, ou qu'il n'y a pas de résultat sur le filtre local
                search_loading: false, // passe à true juste avant la recherche, et repasse à false sur le résultat
                search_origin_name: "", // nom du cheval retenu, lors d'un résultat de la recherche
                add_progress: 0, // passe à 1 au clic sur un cheval
                bool_adding_horse: false, // permet de mettre un verrou, pour éviter les doubles requetes

                search_horse: 0,        // booléen pour afficher ou non le spinner sur bouton recherche
                input_type_detected: 0, // Booléen permettant de bloquer la recherche tant que le type de recherche n'a pas été identifié
                input_type_working: 0,  // Booléen passant à 1 lorsque la recherche de type se fait, repasse à 0 après, même si la recherche n'a pas abouti
                tab_trad_manual_add: [
                    'IK',
                    'IF',
                    'NOR',
                ], // Nom, père mère, race, robe sexe, date de naissance
                tab_data_correspondance: {
                    ajout: {
                        horse_nom:  'birthName',
                        horse_pere: 'sire.birthName',
                        horse_mere: 'dam.birthName',
                        horse_race: 'breedCode',
                        horse_robe: 'colourLabel',
                        horse_sexe: 'sex',
                        horse_date: 'birthDate',
                    },
                    ajout_nom: {
                        horse_nom:  'nom',
                        horse_pere: 'genealogy.sireName',
                        horse_mere: 'genealogy.dameName',
                        horse_race: 'codeRace',
                        horse_robe: 'codeRobe',
                        horse_sexe: 'codeSexe',
                        horse_date: 'dateNaissance',
                        horse_sire: 'numeroSireEncode'
                    }
                },
                input_search_requirements: 5, // Nombre de caractères requis pour que la recherche ne se déclanche
                input_search_recommanded: 8, // Nombre de caractères requis pour que la recherche ne se déclanche

                /* Configuration du tableau : */
                table_busy: true,/* Définit si la table n'est pas encore chargée */
                limit_horse: 0, // Nombre de chevaux déjà ajoutés,

                formatted_horses: [],
                extern_slot_columns: ['button_add'],
                mask_ifce: false,
                nb_horse: 0,
				info_nb_horse: false,
				info_nb_horse_msg: ''
            }
        },
        created: function() {
            this.debouncedInputTypeRecognition = _debounce(this.inputTypeRecognitionV2, 1000)
            this.check_nb_horses()
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.mask_ifce = this.getConfigIfce()

				this.horse_races = await this.getRaces()
				this.horse_robes = await this.getRobes()
				this.horse_sexes = await this.getSexes()

				this.horse_sexes = this.horse_sexes.filter(sexe => sexe.sexe_code == "F")
				this.horse_sexe = this.horse_sexes.find(sexe => sexe.sexe_code == "F")

                if(this.unknown) {
                    this.initUnkownHorseName()
                }
            },

            async checkForm() {
                let el = ''
                let error = false

                for(let i=0; i<this.required_values.length; i++) {
                    el = this.required_values[i]

                    if(this[el] === null || this[el] === undefined) {
                        console.log(el, this[el])
                        error = true
                    }
                }

                if(error) {
                    this.message_erreur_code = "formulaire.erreur_champs_non_remplis"
                    return false
                }

                let horse = {
                    horse_nom: this.horse_nom,
                    horse_sexe: this.horse_sexe.sexe_code,
                    horse_robe: this.horse_robe ? this.horse_robe.robe_code : '',
                    horse_race: this.horse_race ? this.horse_race.race_code : '',
                    horse_datenaissance: this.horse_datenaissance
                }

                let res = await this.createHorseOffline(horse)
                
                if(res !== 0) {
                    if(this.code_onboarding) {
                        const params =  {
                            code: this.code_onboarding,
                            done: 1,
                            skip: 0
                        }

                        this.successToast()
                        await this.$sync.force()

                        this.ok(params)
                        this.shutterPanel().close(this.code_onboarding)
                    }
                    else {
                        await this.addHorseSeasonMare(this.season_id, res, '')
                        this.successToast()
                        await this.$sync.force()
                        const horse_id = this.$sync.replaceWithReplicated('horse', res)

                        this.ok(horse_id)
                        this.resetForm()
                        this.shutterPanel().close('monte-horse-ajout')
                    }

                    return true
                }

                this.message_erreur_code = "error.LEP"
                return false
            },

            check_nb_horses: async function() {
                // On compte le nombre de chevaux déjà ajoutés, via la taille de la whitelist du localstorage
                this.limit_horse = this.getUserHorseLimit()

                this.nb_horse = await this.getNbHorse()
				if(this.limit_horse - this.nb_horse < 10 && this.limit_horse - this.nb_horse > 0)
				{
					if(this.limit_horse - this.nb_horse == 1) {
						this.info_nb_horse_msg = this.getTrad('global.info_limit_horse_unique', [this.limit_horse - this.nb_horse])
					}
					else {
						this.info_nb_horse_msg = this.getTrad('global.info_limit_horse_multiple', [this.limit_horse - this.nb_horse])
					}
					
					this.info_nb_horse = true
				}

                // On regarde sur l'api si on a toujours le droit d'ajouter des nouveaux chevaux 
                this.has_right = await this.checkCanAdd()

                if(!this.has_right) {
                    this.code_erreur = "NR"
                }
            },

            inputTypeRecognitionV2: function(input) {
                // On force le spinner à se masquer
                this.input_type_working = 0
                let result_to_clean = false

                // regex caractères apple
                input = this.clean_input(input)

                // On regarde d'abord si la regex de base match
                if(this.regex_alpha_fr_nc.test(input)) {
                    this.input_type_detected = 0
                    this.code_erreur = "LICD" // Local - Illegal caracter detected
                }
                else {
                    // On démarre l'analyse à patir de 5 caractères, et on lance nos tests de regex pour définir quel type de recherche est fait
                    if(input.length >= this.input_search_requirements) {
                        this.code_erreur = ""

                        // On commence par tester les reges d'erreurs possibles
                        if(!this.checkPotentialError(input)) {

                            // On cherche une preuve de SIRE - on cherche par ordre de précision
                            if(this.regex_sire.test(input)) { // sire ok
                                this.input_type_working = 1
                                this.search_type = "SIRE"
                                this.fetchHorse(input)
                            }

                            // On cherche une preuve d'UELN - on cherche par ordre de précision
                            else if(this.regex_ueln.test(input)) { // sire ok
                                this.input_type_working = 1
                                this.search_type = "UELN"
                                this.fetchHorse(input)
                            }

                            // Si aucune preuve de pattern n'abouti, on fait une recherche par nom
                            else if(this.regex_name_like.test(input)) {
                                this.input_type_working = 1
                                this.search_type = "NAME"
                                this.fetchHorseByName(input)
                            }
                        }
                        else {
                            result_to_clean = true
                        }
                    }
                    else {
                        result_to_clean = true
                    }
                }

                if(result_to_clean) {
                    this.result_horses = []
                }
            },

            checkPotentialError: function(input) {
                // On va tester les différentes regex d'erreurs - on test de la plus simple à la plus restrictive
                let error = false

                /* UELN */
                if(this.regex_ueln_like.test(input)) {

                    if(this.regex_incomplete_ueln_car.test(input)) {
                        error = true
                        this.code_erreur = "LTTL"
                    }
                    else if(this.regex_incomplete_ueln_cle.test(input)) {
                        error = true
                        this.code_erreur = "LTKM"
                    }
                    else if(this.regex_incomplete_ueln_num.test(input)) {
                        error = true
                        this.code_erreur = "LTTN"
                    }
                    // else if(this.regex_ueln_error.test(input)) {
                    //     error = true
                    //     this.code_erreur = "LTMF"
                    // }
                    else if(!this.regex_ueln.test(input)) {
                        error = true
                        this.code_erreur = "LTII"
                    }
                }
                /* SIRE */
                else if(this.regex_sire_like.test(input)) {

                    if(this.regex_incomplete_sire_cle.test(input)) {
                        error = true
                        this.code_erreur = "LKIM"
                    }
                    else if(this.regex_incomplete_sire_num.test(input)) {
                        error = true
                        this.code_erreur = "LNIM"
                    }
                    else if(this.regex_incomplete_sire_car.test(input)) {
                        error = true
                        this.code_erreur = "LTML"
                    }
                    else if(!this.regex_sire.test(input)) {
                        error = true
                        this.code_erreur = "LSII"
                    }
                }

                return error
            },

            fetchHorse: async function(input) {
                this.result_horses = []
                let url = ""

                input = this.accentsTidy(input)
                input = input.toUpperCase()
                if(this.search_type == "SIRE") {
                    url = Constants.SEARCH_SIRE_URL + '?sire='+encodeURI(input)+'&licence_key='+Constants.USER_LICENCE_KEY
                }
                else if (this.search_type == "UELN") {
                    url = Constants.SEARCH_UELN_URL + '?ueln='+encodeURI(input)+'&licence_key='+Constants.USER_LICENCE_KEY
                }

                const response = await this.request.request_get_api("HorseAjout::fetchHorse", url)

                if(response.code_retour === 0) {
                    if(response.retour) {
                        this.result_horses.push(response.retour)
                    }
                }
                else {
                    this.code_erreur = response.code_retour
                }

                // On cache le spinner, et on indique que la recherche du cheval est terminée
                this.input_type_working = 0
                this.search_horse = 0
            },

            fetchHorseByName: async function(name) {
                // On vérifie les verrou
                if(!this.search_loading && !this.search_done) {
                    this.result_horses = []
                    this.search_loading = true

                    const url = Constants.SEARCH_NAME_URL+this.accentsTidy(name.toUpperCase())+"?licence_key="+Constants.USER_LICENCE_KEY
                    await this.request.request_get_api("HorseAjout::fetchHorseByName", url)
                    .then(async (response) => {
                        if(response.code_retour === 0) {
                            if(response.retour) {
                                this.result_horses = await this.format_horse_result(response.retour) // le retour est un tableau, pas besoin de push
                                this.search_done = true // si on a un résultat, on active le verrou pour ne pas relancer la recherche
                                this.search_origin_name = name
                            }
                        }
                    })
                    .catch(e => {
                        const code_erreur = e.response.data.code_retour ? e.response.data.code_retour : "UKE"
                        if(code_erreur == "UKE" && name.length < this.input_search_recommanded) {
                            this.code_erreur = "TMR"
                        }
                        else {
                            this.code_erreur = code_erreur
                        }
                    })

                    // On cache le spinner, et on indique que la recherche du cheval est terminée
                    this.input_type_working = 0
                    this.search_horse = 0
                    this.search_loading = false

                    // On regarde si entre temps, l'input a changé, et qu'on cherche toujours un nom. Si on a pas eu de résultats du webservice, on relance la recherche
                    let input = this.clean_input(this.global_input_search)
                    if(input != name && this.search_type == "NAME" && this.result_horses.length == 0 && input.length >= 5) {

                        this.fetchHorseByName(input)
                    }
                }
                else {
                    this.input_type_working = 0
                }
            },

            addHorseMethod: function(item) {
				if(!item) return

                // Pour empêcher le double clic par ex
                if(this.add_progress !== 0) return

                this.add_progress = 1

                if(this.search_type == "SIRE" || this.search_type == "UELN") {
                    const selected_horse = this.filtred_horses[0]
                    this.addHorseBySire(selected_horse)
                }
                else if(this.search_type == "NAME") {
                    // Pour retrouver le cheval selectionné, on se base sur le sireEncode, unique pour chaque cheval
                    const selected_horse = this.filtred_horses.filter(horse => horse.numeroSireEncode == item.horse_sire)
                    if(selected_horse.length > 0)
                        this.addHorseByName(selected_horse)
                }
            },

            addHorseBySire: async function(horse) {
                try {
                    if(horse != null && horse != 0) {
                        this.bool_adding_horse = true

                        // On valide le cheval auprès du webservice
                        const horse_id = horse.id
                        const url = Constants.VALIDE_HORSE_URL+horse_id+'/?licence_key='+Constants.USER_LICENCE_KEY
                        const response = await this.request.request_get_api("horseAjout::addHorse", url, false, { sync: true })
                        if(response.code_retour === 0) {
                            await this.$sync.runRequiredWhishlist(['horse', 'horse_pedigree'])

                            if(this.code_onboarding) {
                                const params =  {
                                    code: 'horse_create',
                                    done: 1,
                                    skip: 0
                                }

                                this.ok(params)
                                this.shutterPanel().close(this.code_onboarding)
                            }
                            else {
                                this.ok(horse_id)
                                this.resetForm()
                                this.shutterPanel().close('monte-horse-ajout')
                            }

                            return true
                        }

                        this.bool_adding_horse = false
                    }
                }
                catch(e) {
                    this.add_progress = 0
                }
            },

            addHorseByName: async function(horse) {
                try {
                    if(horse.length > 0)
                        horse = horse[0]

                    // On effectue une requete pour récupérer le sire décodé du cheval
                    const encoded_sire = horse.numeroSireEncode
                    const url = Constants.SIRE_DECODE_URL+"?encode_sire="+encoded_sire+"&licence_key="+Constants.USER_LICENCE_KEY

                    if(encoded_sire.length > 0 && this.bool_adding_horse == false) {
                        this.bool_adding_horse = true
                        const response = await this.request.request_get_api("HorseAjout::addHorseByName", url)

                        // Si on a un retour viable
                        if(response.code_retour === 0) {
                            if(response.retour) {
                                const horse = response.retour

                                // On a le sire décodé, on peut effectuer l'ajout du cheval par le processus normal (par sire)
                                await this.addHorseBySire(horse)
                                await this.addHorseSeasonMare(this.season_id, horse.id, '')
                                this.bool_adding_horse = false
                            }
                        }
                        else {
                            this.code_erreur = response.code_retour;
                        }
                    }

                    // On cache le spinner, et on indique que la recherche du cheval est terminée
                    this.input_type_working = 0
                    this.search_horse = 0
                }
                catch(e) {
                    this.add_progress = 0
                }
            },

            get_result_horse_nom: function(horse) {
                if(this.search_type == "NAME") {
                    return horse.nom
                }
                return horse.birthName
            },
            get_resut_horse_pere: function(horse) {
                if(this.search_type == "NAME") {
                    return horse.genealogy.sireName
                }
                return horse.sire.birthName
            },
            get_resut_horse_mere: function(horse) {
                if(this.search_type == "NAME") {
                    return horse.genealogy.dameName
                }
                return horse.dam.birthName
            },
            // Fonction permettant de rajouter des attributs aux objets horse
            format_horse_result: async function(horses) {
                await Common.asyncForEach(horses, (horse) => {
                    horse.clicked = false
                })

                return horses
            },
            clean_input: function(input) {
                input = input.toUpperCase()
                input = input.replace(/[\u2018\u2019]/g, "'")
                input = input.replace(new RegExp(/[èéêë]/g),"e")
                return input
            },
            async format_horses() {
                this.table_busy = false
                this.formatted_horses = []
                if(!this.filtred_horses || this.filtred_horses.length === 0) {
                    return
                }

                if(this.search_type != "NAME") {
                    this.formatted_horses.push(
                        await this.formatDataHorseAjout(this.filtred_horses[0], this.tab_data_correspondance.ajout)
                    )
                }
                else {
                    // On parcourt chaque résultat, pour le formatter
                    for (let index in this.filtred_horses) {
                        const obj_api = this.filtred_horses[index]
                        this.formatted_horses.push(
                            await this.formatDataHorseAjout(obj_api, this.tab_data_correspondance.ajout_nom)
                        )
                    }
                }
            },
            hideIosPurchase() {
                console.log("hideIosPurchase")
                this.code_erreur = ''
                this.has_right = true
            },
            async initUnkownHorseName() {
                this.horse_nom = this.$t('monte.unknown_mare') + (this.contract_num != null ? " #" + this.contract_num : "")
            },
            resetForm() {
                this.horse_nom = ""
                this.horse_robe = null
                this.horse_race = null
                this.horse_datenaissance = ""
                this.global_input_search = ""
            }
        },
        watch: {
            'date_acte' (val){
                this.horse_datenaissance = val
            },
            global_input_search(val) {
                if(val != "") {
                    this.input_type_working = 1
                    // On surveille le changement de l'input nécessitant un realod de la recherche par nom
                    const val_clean = this.clean_input(val)
                    if(val_clean.length < this.search_origin_name.length) {
                        this.search_origin_name = ""
                        this.search_done = false
                    }
                    else if(val_clean.length == this.search_origin_name.length && val_clean != this.search_origin_name) {
                        this.search_origin_name = ""
                        this.search_done = false
                    }

                    this.debouncedInputTypeRecognition(val)
                }
            },
            filtred_horses() {
                this.format_horses()
            }
        },
        computed: {
            error_message_trad: function() {
                if(this.tab_trad_manual_add.indexOf(this.code_erreur) > -1) {
                    return this.getTrad("horse.introuvable")
                }
                else if(this.code_erreur == 'NR') {
                    return this.getTrad("error.NR", [this.limit_horse])
                }
                return this.getTrad("error."+(this.code_erreur == "" ? "UKE" : this.code_erreur))
            },
            filtred_horses: function() {
                // Si la recherche n'est pas par nom, pas de filter à appliquer
                if(this.search_type != "NAME") {
                    return this.result_horses
                }
                const input = this.clean_input(this.global_input_search)
                return this.result_horses.filter(horse => this.accentsTidy(horse.nom.toLowerCase()).includes(this.accentsTidy(input.toLowerCase())))
            },
            isWorking() {
                return this.add_progress == 1
            },
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
        }
    };
</script>
